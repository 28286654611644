if($('#cookie-modal-2022').length > 0) {

  $('#cookie-modal-2022 .cookie-modal-button-all').on('click', function(e) {
    $.post($('meta[name=root_href]').attr('content') + '_cookie/', { consent: 1, type: 'all'});
    $('#cookie-modal-form input:checkbox').not('#cookie-modal-form .cookie-modal-switch-disabled').prop('checked', true);
    $('#cookie-modal-2022').removeClass('open');
  });

  $('#cookie-modal-2022 .cookie-modal-button-config').on('click', function(e) {
    let data = $('#cookie-modal-form').serializeArray();
    data.push({name: 'type', value: 'config'});
    data.push({name: 'consent', value: 0});
    $.post($('meta[name=root_href]').attr('content') + '_cookie/', data);
    $('#cookie-modal-2022').removeClass('open');
  });

function cookiePopupShowAgreement(){
    $('.cookie-modal-content .cookie-modal-form').hide();
    $('.cookie-modal-content .cookie-modal-agreement').show();
    $('#cookie-modal-2022 #cookieModalNavigationDetails').removeClass('cookie-modal-navigation-item-link-active');
    $('#cookie-modal-2022 #cookieModalNavigationAgreement').addClass('cookie-modal-navigation-item-link-active'); 
}

function cookiePopupShowDetails(){
    $('.cookie-modal-content .cookie-modal-agreement').hide();
    $('.cookie-modal-content .cookie-modal-form').show();
    $('#cookie-modal-2022 #cookieModalNavigationAgreement').removeClass('cookie-modal-navigation-item-link-active');
    $('#cookie-modal-2022 #cookieModalNavigationDetails').addClass('cookie-modal-navigation-item-link-active');
}

  cookiePopupShowAgreement();

  $('#cookie-bar-footer').on('click', function(e) {
    $('#cookie-modal-2022').addClass('open');
    cookiePopupShowDetails();
  });

  $('#cookie-modal-2022 #cookieModalNavigationAgreement').on('click', function(e) {
    cookiePopupShowAgreement();
  });

  $('#cookie-modal-2022 #cookieModalNavigationDetails').on('click', function(e) {
    cookiePopupShowDetails();
  });

  $('#cookie-modal-2022 .cookie-modal-button-details').on('click', function(e) {
    $('#cookie-modal-2022 #cookieModalNavigationDetails').trigger('click');
  });
}

$(".cookie-modal-button-all, .cookie-bar-agree").click(function(){
  $("#cookie-modal-form input[type=checkbox]").each(function(){
    $(this).prop( "checked", true );
  });
});

if($('#cookie-bar-2022').length > 0 && $('#cookie-modal-2022').length > 0) {
  $('#cookie-bar-2022 .cookie-bar-agree').on('click', function(e) {
    $.post($('meta[name=root_href]').attr('content') + '_cookie/', { consent: 1, type: 'all'});
    $('#cookie-modal-form input:checkbox').not('#cookie-modal-form .cookie-modal-switch-disabled').prop('checked', true);
    $('#cookie-bar-2022').hide();
  });

  $('#cookie-bar-2022 .cookie-bar-config').on('click', function(e) {
    $('#cookie-modal-2022').addClass('open');
    $('#cookie-bar-2022').hide();
    cookiePopupShowDetails();
  });

}
